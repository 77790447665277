<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="branches"
      @on-selected-rows-change="selectionChanged"
      :search-options="{ enabled: true }"
      class="mb-10"
      styleClass="vgt-table condensed bordered"
    >
      <template slot="table-column" slot-scope="props">
        <span style="font-weight: bold; font-size: 12px; color: black"> {{ props.column.label }} </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- #region Actions Column -->
        <span v-if="props.column.field == 'actions'" style="font-size: 13px">
          <div class="d-flex">
            <v-btn small color="#29B6F6" outlined class="mx-1" @click="(selected.request = props.row), (dialogs.newProduct = true)">
              Products
            </v-btn>
            <v-btn small color="error" outlined class="mx-1" @click="(selected.request = props.row), (dialogs.newProduct = true)">
              Disable
            </v-btn>
          </div>
        </span>
        <!-- #endregion -->
        <!-- #region Other Rows -->
        <span v-else style="font-size: 13px">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <!-- #endregion -->
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { getAllBranches } from "./lib";

export default {
  name: "Branches",
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Code",
          field: "code",
        },
        // {
        //   label: "Action",
        //   field: "actions",
        //   width: "250px",
        // },
      ],
      branches: [],
      selected: {
        branch: null,
      },
      dialogs: {
        products: false,
      },
    };
  },
  methods: {
    async onInit() {
      await this.$store.dispatch("setLoading", true);
      this.branches = await getAllBranches();
      await this.$store.dispatch("setLoading", false);
    },
    selectionChanged() {},
  },
  async mounted() {
    await this.onInit();
  },
};
</script>

<style></style>
