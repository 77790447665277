import axios from "axios";

export async function getAllBranches() {
  try {
    const res = await axios.get(`branch`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}
